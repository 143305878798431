import React from 'react';
import useModal from '@/hooks/useModal';
import Modal from '@/components/Modal';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

interface ModalImageProps {
  src: any;
  alt: string;
  mb?: any;
  mt?: any;
  mr?: any;
  ml?: any;
  width?: any;
  isLong?: boolean;
}

const ModalImage = ({
  src,
  alt,
  mb,
  mt,
  mr,
  ml,
  width,
  isLong,
}: ModalImageProps) => {
  const { isShowing, toggle } = useModal();

  const handleClick = () => {
    toggle();
  };

  return (
    <Box mb={mb} mt={mt} mr={mr} ml={ml} width={width}>
      <StyledImage alt={alt} image={getImage(src)} onClick={handleClick} />
      <Modal
        isShowing={isShowing}
        hide={toggle}
        src={src}
        alt={alt}
        isLong={isLong}
      />
    </Box>
  );
};

const defaultProps: ModalImageProps = {
  mb: '',
  mt: '',
  ml: '',
  mr: '',
  width: '',
  isLong: false,
};

ModalImage.defaultProps = defaultProps;

const StyledImage = styled(GatsbyImage)`
  cursor: pointer;
`;

export default ModalImage;
