import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

interface ModalProps {
  isShowing: boolean;
  hide: () => void;
  src: any;
  alt: string;
  isLong: boolean;
}

const Modal = ({ isShowing, hide, src, alt, isLong }: ModalProps) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <ModalOverlay onClick={hide}>
            <ModalWrapper isLong={isLong}>
              <GatsbyImage
                alt={alt}
                image={getImage(src)}
                imgStyle={{
                  objectFit: 'contain',
                  objectPosition: 'center top',
                }}
              />
            </ModalWrapper>
          </ModalOverlay>
        </>,
        document.body,
      )
    : null;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled.div<{ isLong: boolean }>`
  margin: 0 auto;
  z-index: 999999;
  max-width: ${({ isLong }) => (isLong ? '70%' : '80%')};
  outline: 0;
  max-height: 90%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-width: ${({ isLong }) => (isLong ? '400px' : '80%')};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    max-width: ${({ isLong }) => (isLong ? '400px' : '800px')};
  }
`;

export default Modal;
