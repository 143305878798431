import * as React from 'react';
import { Flex, Box } from 'rebass/styled-components';
import { ContentWrapper } from './ContentWrapper';

export const Row = ({ left, right, flexProps = {}, ...boxProps }) => (
  <ContentWrapper maxWidth={1000} {...boxProps}>
    <Flex mb={[3]} flexWrap="wrap" {...flexProps}>
      <Box
        width={[1, 1, '33.3vw']}
        mb={[3, 3, 0]}
        // maxWidth="333px"
      >
        {left}
      </Box>
      <Box
        width={[1, 1, 'calc(66.7vw)']}
        maxWidth="calc((100vw - 1000px ) / 2 + 670px )"
      >
        {right}
      </Box>
    </Flex>
  </ContentWrapper>
);
