import React from 'react';
import { Link } from 'rebass/styled-components';
import styled from 'styled-components';

export const TextLink = ({ href, children }: TextLinkProps) => (
  <StyledLink href={href} target="_blank">
    {children}
  </StyledLink>
);

interface TextLinkProps {
  children: React.ReactChild;
  href: string;
}

const StyledLink = styled(Link)`
  border-bottom: 1px solid #a1a5b5;

  :hover {
    border-bottom: 1px solid #171b3d;
  }
`;
