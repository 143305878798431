import styled from 'styled-components';
import { Box, BoxProps } from 'rebass/styled-components';
import { styledTheme } from '@/styledTheme';

export const ContentWrapper = styled(Box)<
  { maxWidth?: number | string } & BoxProps
>`
  margin-left: auto;
  margin-right: auto;
  width: calc(100vw - 40px);
  max-width: ${({ maxWidth }) =>
    typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: calc(100vw - 80px);
  }
`;

ContentWrapper.defaultProps = { maxWidth: styledTheme.contentWidth };
