const fonts = {
  body: 'Europa, Helvetica, sans serif',
  heading: 'Archia, Helvetica, sans serif',
  text: 'Europa, Helvetica, sans serif',
};

const colors = {
  primary: '#171B3D',
  tag: '#72788F',
  secondary: '#A1A5B5',
  typeSecondary: '#BDC1D0',
  divider: '#D6D8E0',
  white: '#fff',
};

const fontSizes = [12, 14, 16, 20, 24, 32, 44, 70];

const breakpoints = ['480px', '768px', '1024px', '1200px'];

const buttonBase = {
  bg: 'transparent',
  borderRadius: 0,
  display: 'flex',
  flexWrap: 'no-wrap',
  fontFamily: fonts.heading,
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  transition: '0.3s ease-in-out',
  whiteSpace: 'nowrap',
  minWidth: 200,
  fontSize: fontSizes[3],
  padding: 21,
  '@media screen and (min-width: 768px)': {
    fontSize: fontSizes[4],
    padding: 24,
  },
};

export const styledTheme = {
  extendedContentWidth: 1290,
  contentWidth: 1200,

  breakpoints,
  fontSizes,
  colors,
  space: [0, 8, 15, 30, 60, 120, 240],
  fonts,
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
    text: '32px',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    decoration: 'none',
    color: 'inherit',
    fontFamily: fonts.body,
  },
  buttons: {
    outline: {
      color: 'white',
      border: `1px solid ${colors.white}`,
      ...buttonBase,
      ':hover': {
        cursor: 'pointer',
        color: colors.primary,
        boxShadow: `inset 0 -3.25em 0 0 ${colors.white}`,
      },
    },
    primary: {
      color: colors.primary,
      border: `2px solid ${colors.primary}`,
      ...buttonBase,
      ':hover': {
        cursor: 'pointer',
        color: colors.white,
        boxShadow: `inset 0px 0px 0px 50px ${colors.primary}`,
      },
    },
  },
};
