import { Row } from '@/components/Row';
import { Text } from 'rebass/styled-components';
import { ContentWrapper } from '@/components/ContentWrapper';
import { styledTheme } from '@/styledTheme';
import { TextLink } from '@/components/ProjectPage/TextLink';
import ModalImage from "../../../../src/components/ProjectPage/ModalImage";
import * as React from 'react';
export default {
  Row,
  Text,
  ContentWrapper,
  styledTheme,
  TextLink,
  ModalImage,
  React
};